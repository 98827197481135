import React from "react";
import { node } from "prop-types";

import SocketProvider from "./socket/SocketContext";
import AuthProvider from "./auth/auth.context";
import SettingProvider from "./data/SettingContext";
import DataProvider from "./data/DataContext";
import ListProvider from "./list/list.context";
import LoadingProvider from "./loading/LoadingContext";
import ThemeProvider from "./data/ThemeContext";
import CountProvider from "./data/CountContext";
import VisitorContextProvider from "./data/VisitorContext";
import ResearcherProfileProvider from "./data/ResearcherProfileContext";

import { I18nextProvider } from 'react-i18next'
import i18n from './../i18n/i18n'

const ContextProvider = ({ children }) => {
    return (
        <I18nextProvider i18n={i18n}>
            <LoadingProvider>
                <AuthProvider>
                    <VisitorContextProvider>
                        <DataProvider>
                            <ResearcherProfileProvider>
                                <ListProvider>
                                    <SettingProvider>
                                        <CountProvider>
                                            <ThemeProvider>
                                                <SocketProvider>
                                                    {children}
                                                </SocketProvider>
                                            </ThemeProvider>
                                        </CountProvider>
                                    </SettingProvider>
                                </ListProvider>
                            </ResearcherProfileProvider>
                        </DataProvider>
                    </VisitorContextProvider>
                </AuthProvider>
            </LoadingProvider>
        </I18nextProvider>
    );
};

ContextProvider.propTypes = {
    children: node.isRequired
};

export default ContextProvider;
