import React, {createContext, useContext, useState} from 'react'
import { AuthContext } from '../auth/auth.context';
import { UserDashboardApi } from '@/api';

const initialState = {
    isScientistProfileId: null,
    isLoading: false
};

export const ResearcherProfileContext = createContext<any>(initialState);

export const ResearcherProfileProvider = ({ children }) => {
    const { userId } = useContext(AuthContext);

    const [isScientistProfileId, setIsScientistProfileId] = useState<any>(initialState.isScientistProfileId);
    const [isLoading, setLoading] = useState<boolean>(initialState.isLoading);

    React.useEffect(()=> {
        if(userId){
            loadData()
        }
    }, [userId])

    const loadData = (): Promise<any> => {
        return new Promise((resolve, reject) => {
            setLoading(true);
            const payload = {
                userId: userId
            }
            UserDashboardApi.getResearchWiseDataCount(payload)
                .then(res => {
                    // setIsScientistProfileIdProfile(res.data.data.result.is_scientist_profile);
                    // setIsScientistProfileIdProfileApproved(res.data.data.result.is_scientist_profile_approved);
                    if (res.data.data.result?.is_scientist_profile_id){
                        setIsScientistProfileId(res.data.data.result?.is_scientist_profile_id ?? null);
                    }

                    setLoading(false);
                    resolve(res);
                })
                .catch(err => {
                    setLoading(false);
                    reject(err);
                });
        });
    }


    const contextValue = {
        isScientistProfileId, setIsScientistProfileId, isLoading
    };

    return (
        <ResearcherProfileContext.Provider value={contextValue}>
            {children}
        </ResearcherProfileContext.Provider>
    );
};

export default ResearcherProfileProvider;
